import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src1524589205/src/src/templates/docs/index.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Android SDK v2 Releases`}</h1>
    <h2>{`2.2.0`}</h2>
    <ul>
      <li parentName="ul">{`Migrate to Enigmatic-Smile GitHub organization`}</li>
    </ul>
    <h2>{`2.1.2`}</h2>
    <ul>
      <li parentName="ul">{`Updated consent text for Mastercard cards.`}</li>
    </ul>
    <h2>{`2.1.1`}</h2>
    <ul>
      <li parentName="ul">{`Add a few more card verification metrics tracking features to help us improve our services.`}</li>
    </ul>
    <h2>{`2.1.0`}</h2>
    <ul>
      <li parentName="ul">{`Add card enrollment & verification metrics tracking features to help us improve our services.`}</li>
    </ul>
    <h2>{`2.0.0`}</h2>
    <ul>
      <li parentName="ul">{`Transaction Stream Android SDK is now Stable!
We are thrilled to announce a significant milestone in our journey – the Transaction Stream SDK has officially transitioned from its beta phase to a stable release!
After months of rigorous testing, we are confident that Transaction Stream SDK is ready to empower your projects so you can create more valuable, engaging experiences for your customers.`}</li>
    </ul>
    <h2>{`2.0.0-beta18`}</h2>
    <ul>
      <li parentName="ul">{`Removed card scanning feature, please make sure to delete the `}<inlineCode parentName="li">{`Fidel.enableCardScanner`}</inlineCode>{` and `}<inlineCode parentName="li">{`Fidel.shouldAutoScanCard`}</inlineCode>{` configuration.`}</li>
    </ul>
    <h2>{`2.0.0-beta17`}</h2>
    <ul>
      <li parentName="ul">{`Updated the micro-charge descriptor text to the correct one.`}</li>
      <li parentName="ul">{`Fixed a security issue where the screenshots were taken in the Task Switcher.`}</li>
    </ul>
    <h2>{`2.0.0-beta16`}</h2>
    <ul>
      <li parentName="ul">{`Added Norway to the countries supported by the SDK.`}
        <ul parentName="li">
          <li parentName="ul">{`If you specified the `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` property, remember to add Norway, to have it in the `}<inlineCode parentName="li">{`Country of issue`}</inlineCode>{` field.`}</li>
          <li parentName="ul">{`If you didn't customize the `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` property, Norway will automatically show up in the `}<inlineCode parentName="li">{`Country of issue`}</inlineCode>{` field after you integrate the newest version of the SDK.`}</li>
        </ul>
      </li>
    </ul>
    <h2>{`2.0.0-beta15`}</h2>
    <ul>
      <li parentName="ul">{`Card scanning will now be disabled by default, use `}<inlineCode parentName="li">{`Fidel.enableCardScanner = true`}</inlineCode>{` to enable card scanning.`}</li>
    </ul>
    <h2>{`2.0.0-beta14`}</h2>
    <ul>
      <li parentName="ul">{`Useful features for corporate card enrollment, but not only:`}
        <ul parentName="li">
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`Fidel.thirdPartyVerificationChoice`}</inlineCode>{` property to allow cardholders to choose between verifying the card on the spot (as previously available) or indicate that the cardholder does not have access to the card statement and needs to delegate card verification to a third-party entity.`}</li>
          <li parentName="ul">{`The `}<inlineCode parentName="li">{`Fidel.verifyCard`}</inlineCode>{` function to attempt card verification for a previously enrolled card. This function can be used for either the cardholder or a third-party entity (that cannot enroll cards, but can verify cards).`}</li>
          <li parentName="ul"><em parentName="li">{`(Experimental feature)`}</em>{` The `}<inlineCode parentName="li">{`Fidel.onCardVerificationChoiceSelected`}</inlineCode>{` callback that communicates the cardholder's choice and intention to either verify the card on the spot (because the cardholder has access to the card statement) or to express the intention to delegate card verification to a third-party entity.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Adds the `}<inlineCode parentName="li">{`Fidel.onCardVerificationStarted`}</inlineCode>{` callback which communicates that card verification has started for a card that was enrolled in a Fidel program. This callback provides the consent details that are created, in order to start the card verification.`}</li>
      <li parentName="ul">{`Changes in the verification screen text that gives a better description on how the micro-charge will be displayed in the card statement`}</li>
      <li parentName="ul">{`Removes the currency symbol during the input of the verification token (the micro-charge amount).`}</li>
    </ul>
    <h2>{`2.0.0-beta13`}</h2>
    <ul>
      <li parentName="ul">{`Fix the error that caused the user to be stuck on the "Link card" page after choosing to reconnect a card.`}</li>
    </ul>
    <h2>{`2.0.0-beta12`}</h2>
    <ul>
      <li parentName="ul">{`This version displays better text to cardholders explaining that card verification micro-charges will be refunded within 72 hours.`}</li>
    </ul>
    <h2>{`2.0.0-beta11`}</h2>
    <ul>
      <li parentName="ul">{`Fixes some result errors.`}</li>
    </ul>
    <h2>{`2.0.0-beta10`}</h2>
    <ul>
      <li parentName="ul">{`This version provides a comprehensive error message in case the micro-charge fails. You don't need to make any changes to your code in order to use this version.`}</li>
    </ul>
    <h2>{`2.0.0-beta9`}</h2>
    <ul>
      <li parentName="ul">{`Removed the `}<inlineCode parentName="li">{`FidelResult.VerificationSuccessful`}</inlineCode>{`. Replaced with `}<inlineCode parentName="li">{`FidelResult.Verification`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`We are now providing `}<inlineCode parentName="li">{`cardId`}</inlineCode>{` as part of the `}<inlineCode parentName="li">{`FidelResult.Verification`}</inlineCode></li>
    </ul>
    <h2>{`2.0.0-beta8`}</h2>
    <ul>
      <li parentName="ul">{`We've now blocked the SDK for cardholders that use insecure devices. If a cardholder has an insecure device, you'll receive an error of type `}<inlineCode parentName="li">{`DeviceNotSecure`}</inlineCode>{` via the `}<inlineCode parentName="li">{`onResult`}</inlineCode>{` callback that you can set. Please treat this error in the most appropriate way for your app.`}</li>
      <li parentName="ul">{`We stopped allowing copying card details from our SDK's input fields. Only the "Paste" operation is allowed.`}</li>
      <li parentName="ul">{`Allow expiration date editing without switching to country selection.`}</li>
    </ul>
    <h2>{`2.0.0-beta7`}</h2>
    <p>{`Support card verification in GBP for UK issued cards.`}</p>
    <h2>{`2.0.0-beta6`}</h2>
    <p>{`We updated the "Fidel" name to "Fidel API".`}</p>
    <h2>{`2.0.0-beta5`}</h2>
    <p>{`We updated the Fidel logo.`}</p>
    <h2>{`2.0.0-beta4`}</h2>
    <p>{`We merged a few changes that we did in our public SDK:`}</p>
    <ol>
      <li parentName="ol">{`Add support for the `}<inlineCode parentName="li">{`resConfigs`}</inlineCode>{` optimization parameter. Now, if your app attempts to optimize resources and set the `}<inlineCode parentName="li">{`resConfigs`}</inlineCode>{` parameter in gradle (which might remove some of our string resources), our SDK will display either strings in the default language (English) or in one of the languages supported by your app. This also depends on the device language and how the Android system resolves string resources.`}</li>
      <li parentName="ol">{`Added the `}<inlineCode parentName="li">{`defaultSelectedCountry`}</inlineCode>{` property which sets the country that will be selected by default, when opening the card enrollment screen.`}</li>
      <li parentName="ol">{`Removed the card scanning confirmation screen. Users can confirm their card information by checking the information in the Fidel card enrollment screen.`}</li>
    </ol>
    <h2>{`2.0.0-beta3`}</h2>
    <ol>
      <li parentName="ol">{`The Card Verification steps/screen now supports locales that use ","(comma) instead of "."(dot) as amount digits separator. The view will force the display of the amount with a "."(dot) digit separator.`}</li>
      <li parentName="ol">{`If the user did not complete the card verification step (but did succeed to enroll a card) the SDK opens the card verification step when the app is re-opened. We made a few changes related to this process:`}
        <ul parentName="li">
          <li parentName="ul">{`Before opening the card verification step automatically, when the app is re-launched, we make sure that the Fidel SDK is configured correctly. If it is not configured correctly (it misses parameters or they are not valid) then you'll receive an appropriate error, if you're using the `}<inlineCode parentName="li">{`Fidel.onResult`}</inlineCode>{` closure. In case the SDK was not configured correctly, the card verification step will not be opened automatically.`}</li>
          <li parentName="ul">{`If the user did not complete the card verification step, closed the flow at this step, but does not quit your app and attempts to re-connect the card (by pressing on the button in your app), we'll take the user directly to the card verification step, to continue the process. Previously the user had to enroll the card again, which would have caused an error. By letting the user continuing the card verification process, we avoid errors in this scenario.`}</li>
        </ul>
      </li>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`targetSdkVersion`}</inlineCode>{` and `}<inlineCode parentName="li">{`compileSdkVersion`}</inlineCode>{` have been updated to version `}<inlineCode parentName="li">{`31`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Updated the following dependencies:`}
        <ol parentName="li">
          <li parentName="ol"><inlineCode parentName="li">{`androidx.core:core-ktx:1.8.0-alpha02`}</inlineCode></li>
          <li parentName="ol"><inlineCode parentName="li">{`androidx.appcompat:appcompat:1.4.1`}</inlineCode></li>
          <li parentName="ol"><inlineCode parentName="li">{`androidx.constraintlayout:constraintlayout:2.1.3`}</inlineCode></li>
          <li parentName="ol"><inlineCode parentName="li">{`com.google.android.gms:play-services-auth:20.0.1`}</inlineCode></li>
        </ol>
      </li>
    </ol>
    <h2>{`2.0.0-beta2`}</h2>
    <ol>
      <li parentName="ol">{`Renamed the package of the SDK to `}<inlineCode parentName="li">{`com.fidelapi`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Renamed the `}<inlineCode parentName="li">{`present`}</inlineCode>{` function (which opened the Fidel card connection UI) to `}<inlineCode parentName="li">{`start`}</inlineCode>{`, in order to align with the web SDK.`}</li>
      <li parentName="ol">{`Rename `}<inlineCode parentName="li">{`privacyURL`}</inlineCode>{` property to `}<inlineCode parentName="li">{`privacyPolicyUrl`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Rename `}<inlineCode parentName="li">{`termsConditionsURL`}</inlineCode>{` property to `}<inlineCode parentName="li">{`termsAndConditionsUrl`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Rename `}<inlineCode parentName="li">{`autoScan`}</inlineCode>{` property to `}<inlineCode parentName="li">{`shouldAutoScanCard`}</inlineCode>{`.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`CardScheme`}</inlineCode>{`, `}<inlineCode parentName="li">{`Country`}</inlineCode>{`, `}<inlineCode parentName="li">{`ProgramType`}</inlineCode>{` data types are not be subtypes of the `}<inlineCode parentName="li">{`Fidel`}</inlineCode>{` class anymore. They are separate enum classes now.`}</li>
      <li parentName="ol">{`We made the `}<inlineCode parentName="li">{`companyName`}</inlineCode>{` property mandatory, as it is reflected in the consent text the user has to agree with before enrolling a card, so it made sense to make it mandatory. If the property is not set, you will not be able to open the Fidel card connection UI, but receive an error result immediately after the attempt to start the card connection process.`}</li>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`allowedCountries`}</inlineCode>{` property has been transformed from an `}<inlineCode parentName="li">{`List`}</inlineCode>{` to a `}<inlineCode parentName="li">{`Set`}</inlineCode>{`. If you allow multiple countries, they will now be sorted alphabetically.`}</li>
      <li parentName="ol">{`Updated dependencies:`}
        <ol parentName="li">
          <li parentName="ol"><inlineCode parentName="li">{`androidx.constraintlayout:constraintlayout`}</inlineCode>{` to version `}<inlineCode parentName="li">{`2.1.2`}</inlineCode>{`.`}</li>
          <li parentName="ol"><inlineCode parentName="li">{`androidx.annotation:annotation`}</inlineCode>{` to version `}<inlineCode parentName="li">{`1.3.0`}</inlineCode>{`.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Remove the `}<inlineCode parentName="li">{`getInstance()`}</inlineCode>{` function of the `}<inlineCode parentName="li">{`Fidel`}</inlineCode>{` facade class for consistency purposes. We’ll keep using static properties and functions.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`onMainActivityCreate`}</inlineCode>{` function is now a static function, so it will be called like in the following line: `}<inlineCode parentName="li">{`Fidel.onMainActivityCreate(this)`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Improved the result objects and we are providing `}<strong parentName="li">{`more`}</strong>{` types of results, during the card connection processes. In order to achieve this, we made a few changes to our SDK results related APIs:`}
        <ol parentName="li">
          <li parentName="ol">{`Removed the ability to receive results in the `}<inlineCode parentName="li">{`onActivityResult`}</inlineCode>{` function, because it allows receiving only one result during the card connection process and only when the process is finished. However, users can receive multiple errors during the card connection process, but also a successful `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{` and a `}<inlineCode parentName="li">{`VerificationSuccessful`}</inlineCode>{` result.`}</li>
          <li parentName="ol">{`In order to handle a result, you have to set the `}<inlineCode parentName="li">{`Fidel.onResult`}</inlineCode>{` property. We removed the `}<inlineCode parentName="li">{`fidelCardLinkingObserver`}</inlineCode>{` and the `}<inlineCode parentName="li">{`FidelCardLinkingObserver`}</inlineCode>{` interface. The `}<inlineCode parentName="li">{`Fidel.onResult`}</inlineCode>{` callback can be called multiple times during a card connection flow. In order to receive results during the entire card connection process, please set the `}<inlineCode parentName="li">{`onResult`}</inlineCode>{` property in your Main Activity’s `}<inlineCode parentName="li">{`onCreate`}</inlineCode>{` function, like all other Fidel configuration properties.`}</li>
          <li parentName="ol">{`The possible results that you can handle are subclasses of `}<inlineCode parentName="li">{`FidelResult`}</inlineCode>{`(sealed Kotlin class). They are the following:`}
            <ol parentName="li">
              <li parentName="ol"><inlineCode parentName="li">{`data class Enrollment(val enrollmentResult: EnrollmentResult)`}</inlineCode>{`; `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{` was previously named `}<inlineCode parentName="li">{`LinkResult`}</inlineCode>{`.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`object VerificationSuccessful`}</inlineCode>{`.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`data class Error(val error: FidelError)`}</inlineCode>{`; `}<inlineCode parentName="li">{`FidelError`}</inlineCode>{` was previously named `}<inlineCode parentName="li">{`LinkResultError`}</inlineCode>{`.`}</li>
            </ol>
          </li>
          <li parentName="ol">{`Renamed the `}<inlineCode parentName="li">{`LinkResult`}</inlineCode>{` class to `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{`.`}</li>
          <li parentName="ol">{`Transformed the `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{` into a Kotlin data class.`}</li>
          <li parentName="ol">{`Renamed a few properties of the `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{` class and for some properties, we also changed their data types which are more suitable:`}
            <ol parentName="li">
              <li parentName="ol"><inlineCode parentName="li">{`id`}</inlineCode>{` has been renamed to `}<inlineCode parentName="li">{`cardId`}</inlineCode>{`.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`created`}</inlineCode>{` has been renamed to `}<inlineCode parentName="li">{`enrollmentDate`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Changed data type of `}<inlineCode parentName="li">{`enrollmentDate`}</inlineCode>{` from `}<inlineCode parentName="li">{`String`}</inlineCode>{` to a `}<inlineCode parentName="li">{`Long`}</inlineCode>{`, that you can use with your preferred Android date & time APIs.`}</li>
              <li parentName="ol">{`Renamed the `}<inlineCode parentName="li">{`live`}</inlineCode>{` property to `}<inlineCode parentName="li">{`isLive`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed `}<inlineCode parentName="li">{`firstNumbers`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardFirstNumbers`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed `}<inlineCode parentName="li">{`lastNumbers`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardLastNumbers`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed `}<inlineCode parentName="li">{`expYear`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardExpirationYear`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed `}<inlineCode parentName="li">{`expMonth`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardExpirationMonth`}</inlineCode>{`.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`expDate`}</inlineCode>{` of type String was removed, as we already provide `}<inlineCode parentName="li">{`cardExpirationYear`}</inlineCode>{` and `}<inlineCode parentName="li">{`cardExpirationMonth`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Renamed `}<inlineCode parentName="li">{`scheme`}</inlineCode>{` to `}<inlineCode parentName="li">{`cardScheme`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Changed the data type of `}<inlineCode parentName="li">{`cardSheme`}</inlineCode>{` from `}<inlineCode parentName="li">{`String`}</inlineCode>{` (which used internal scheme identifying strings as values) to `}<inlineCode parentName="li">{`CardScheme`}</inlineCode>{`, our custom data type to provide type safety and which was already used for the `}<inlineCode parentName="li">{`Fidel.supportedCardSchemes`}</inlineCode>{` property.`}</li>
              <li parentName="ol"><inlineCode parentName="li">{`countryCode`}</inlineCode>{` has been renamed to `}<inlineCode parentName="li">{`cardIssuingCountry`}</inlineCode></li>
              <li parentName="ol">{`Changed the data type of `}<inlineCode parentName="li">{`cardIssuingCountry`}</inlineCode>{` from `}<inlineCode parentName="li">{`String`}</inlineCode>{` (which used identifiers of countries as values) to `}<inlineCode parentName="li">{`Country`}</inlineCode>{`, our custom data type that is easier to work with in Swift and that it was already used for the `}<inlineCode parentName="li">{`Fidel.allowedCountries`}</inlineCode>{` property.`}</li>
              <li parentName="ol">{`Removed the `}<inlineCode parentName="li">{`getError`}</inlineCode>{` function. `}</li>
            </ol>
          </li>
          <li parentName="ol">{`Changes in the `}<inlineCode parentName="li">{`LinkResultError`}</inlineCode>{` class:`}
            <ol parentName="li">
              <li parentName="ol">{`Renamed it to `}<inlineCode parentName="li">{`FidelError`}</inlineCode>{` as now it’s possible to receive errors during the card verification process, not just during the card enrollment process.`}</li>
              <li parentName="ol">{`Changed data type of the date property from `}<inlineCode parentName="li">{`String`}</inlineCode>{` to a `}<inlineCode parentName="li">{`Long`}</inlineCode>{`, that you can use with your preferred Android date & time APIs.`}</li>
              <li parentName="ol">{`Renamed the `}<inlineCode parentName="li">{`code`}</inlineCode>{` property to `}<inlineCode parentName="li">{`type`}</inlineCode>{`.`}</li>
              <li parentName="ol">{`Changed the data type of the `}<inlineCode parentName="li">{`type`}</inlineCode>{` property from `}<inlineCode parentName="li">{`String`}</inlineCode>{` to `}<inlineCode parentName="li">{`FidelErrorType`}</inlineCode>{` , which is a sealed class introduced to identify types of errors easily in Kotlin (please check this class to see the types of errors that you can handle).`}</li>
            </ol>
          </li>
          <li parentName="ol">{`No results are Parcelable anymore, as that is no longer necessary.`}</li>
          <li parentName="ol">{`All properties of our result instances (of `}<inlineCode parentName="li">{`FidelError`}</inlineCode>{` & `}<inlineCode parentName="li">{`EnrollmentResult`}</inlineCode>{`) are now immutable (`}<inlineCode parentName="li">{`val`}</inlineCode>{` constants)`}</li>
          <li parentName="ol">{`All properties of possible responses are correctly optional or non-optional.`}</li>
          <li parentName="ol">{`It’s possible to handle errors with the type `}<inlineCode parentName="li">{`UserCanceled`}</inlineCode>{` during any stage of the card verification flow, if the user cancels it.`}</li>
        </ol>
      </li>
    </ol>
    <h2>{`2.0.0-beta1`}</h2>
    <ul>
      <li parentName="ul">{`Renamed the `}<inlineCode parentName="li">{`apiKey`}</inlineCode>{` property to `}<inlineCode parentName="li">{`sdkKey`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Added the `}<inlineCode parentName="li">{`programType`}</inlineCode>{` property and the `}<inlineCode parentName="li">{`ProgramType`}</inlineCode>{` enum to specify the program type that you'll use in your app.`}</li>
      <li parentName="ul">{`If the `}<inlineCode parentName="li">{`programType`}</inlineCode>{` property is set to `}<inlineCode parentName="li">{`.transactionStream`}</inlineCode>{`, users will be able to start the card verification flow.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      